body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "GoldmNanum Brush ScriptanBold";
  src: local("Nanum Brush Script"),
   url("./assets/fonts/NanumBrushScript-Regular.ttf") format("truetype");
  font-weight: bold;
  }


.nanum-brush-font {
  font-family: "Nanum Brush Script";
 }

.under-construction {
  /* background-image: url('./assets/images/back.jpg');
  background-repeat: no-repeat;
  background-size: cover; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}